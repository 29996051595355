@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-primary {
    @apply rounded-md border-0 bg-slate-100 p-2 px-6;
  }
}

/* width */
::-webkit-scrollbar {
  width: 6px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #dadbd300;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #444147;
  border-radius: 100vh;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #363338;
}

/* Change Autocomplete styles in Chrome*/
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 1000px #1b1a38 inset !important;
  -webkit-text-fill-color: #818191 !important;
  caret-color: #818191 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  transition: background-color 5000s ease-in-out 0s;
}
